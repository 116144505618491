import Contact from "./components/Contact";
import Home from "./components/Home";
import NavBar from "./components/NavBar";
import Skills from "./components/Skills";
import Work from "./components/Work";
import { Link } from "react-scroll";

function App() {
  return (
    <div >
      <NavBar/>
      <Home/>
      <Skills/>
      <Work/>
      <Contact/>
    </div>
  );
}

export default App;
