import React, { useState } from 'react';
import logo from '../assets/logo.png'
import {FaBars, FaTimes, FaGithub, FaLinkedin} from 'react-icons/fa'
import { HiOutlineMail } from 'react-icons/hi';
import {BsFillPersonLinesFill} from 'react-icons/bs'
import { Link } from 'react-scroll';
const NavBar = () => {
    const [nav, setNav] = useState(false)
    const handleClick = ()=> setNav(!nav)

    return <div className='fixed w-full h-[80px] flex justify-between items-center px-4 bg-[#0a192f] text-gray-300 '>
        <div className='hover:cursor-pointer'>
            <Link to="home" smooth={true} duration={500}> 
            <img src={logo} alt="Logo" style={{width:"50px"}}/>
            </Link> 
        </div>

        {/* Menu */}
            <ul className='hidden md:flex'>
                <li><Link to="home" smooth={true} duration={500}>Home</Link></li>
                <li><Link to="skills" smooth={true} duration={500}>Skills</Link></li>
                <li><Link to="work" smooth={true} duration={500}>Work</Link></li>
                <li><Link to="contact" smooth={true} duration={500}>Contact</Link></li>
            </ul>

        {/* Hamburgers */}
        <div className='md:hidden z-10' onClick={handleClick}>{nav? <FaTimes/> : <FaBars/>}</div>

        {/* mobileMenu */}
        <ul className={nav? "absolute top-0 left-0 w-full h-screen bg-[#0a192f] flex flex-col justify-center items-center": "hidden"}>
                <li className='py-6 text-4xl'><Link onClick={handleClick} to="home" smooth={true} duration={500}>Home</Link></li>
                <li className='py-6 text-4xl'><Link onClick={handleClick} to="skills" smooth={true} duration={500}>Skills</Link></li>
                <li className='py-6 text-4xl'><Link onClick={handleClick} to="work" smooth={true} duration={500}>Work</Link></li>
                <li className='py-6 text-4xl'><Link onClick={handleClick} to="contact" smooth={true} duration={500}>Contact</Link></li>
        </ul>


        {/* social icons */}
        <div className='hidden lg:flex flex-col fixed left-0 top-[35%]'>
            <ul>
                <li className='w-[160px] h-[60px] flex justify-between items-center ml-[-100px] hover:ml-[-0px] duration-300 bg-blue-600'>
                    <a className='flex justify-between items-center w-full text-gray-300' href='https://www.linkedin.com/in/rahul-padhiyar-bb7b171b5' target="_blank"  rel="noreferrer">Linkedin <FaLinkedin size={30}/></a>
                </li>
                <li className='w-[160px] h-[60px] flex justify-between items-center ml-[-100px] hover:ml-[-0px] duration-300 bg-[#333333]'>
                    <a className='flex justify-between items-center w-full text-gray-300' href='https://github.com/rahul769878' target="_blank"  rel="noreferrer">GitHub <FaGithub size={30}/></a>
                </li>
                <li className='w-[160px] h-[60px] flex justify-between items-center ml-[-100px] hover:ml-[-0px] duration-300 bg-[#6fc2b0]'>
                    <a className='flex justify-between items-center w-full text-gray-300' href='mailto:rahuldev9273@gmail.com'>Mail <HiOutlineMail size={30}/></a>
                </li>
                <li className='w-[160px] h-[60px] flex justify-between items-center ml-[-100px] hover:ml-[-0px] duration-300 bg-[#565f69]'>
                    <a className='flex justify-between items-center w-full text-gray-300' href='https://docs.google.com/document/d/1CXTgn8knJI1PM-jCTffMiSfZl8-Qcxjw/edit?usp=sharing&ouid=101110282400199520269&rtpof=true&sd=true' target="_blank"  rel="noreferrer">Resume <BsFillPersonLinesFill size={30}/></a>
                </li> 
            </ul>
        </div>
    </div>;
}


export default NavBar;