import React from 'react';
import FitclubImg from '../assets/projects/fitclub.png'
import CryptohunterImg from '../assets/projects/cryptohunter.png'

const Work = () => {
    return <div name="work" className='w-full bg-[#0a192f] md:h-screen text-gray-300'>
       <div className='max-w-[1000px] mx-auto p-4 flex flex-col justify-center w-full h-full'>
        <div className='pb-8'>
            <p className='text-4xl font-bold border-b-4 border-pink-600 text-gray-300 inline'>Work</p>
            <p className='py-6'>// Check out some of my recent work</p>
        </div>
        
        {/* Container */}
        <div className='grid sm:grid-cols-2 md:grid-cols-3 gap-4'>

            {/* Grid item */}
            <div style={{backgroundImage: `url(${FitclubImg})`}} className='shadow-lg shadow-[#040c16] group container rounded-md flex justify-center items-center mx-auto content-div object-contain h-48 w-96  '>

                {/* Hover Effect */}
                <div className='opacity-0 group-hover:opacity-100 content-div'>
                    <div className='text-center justify-center mt-[60px]'> 
                    <span className='text-2xl font-bold text-white '>
                      React Js Application
                    </span>
                    </div>
                    <div className='pt-[15px] text-center    '>
                        <a href="https://fitclub-f3e.pages.dev/"  target="_blank"  rel="noreferrer"> 
                        <button className='text-center rounded-lg px-4 py-3 m
                        -2 bg-white text-gray-700 font-bold text-lg '>Demo</button> </a>
                        <a href='/'> 
                        <button className='text-center rounded-lg px-4 py-3 m-2 bg-white text-gray-700 font-bold text-lg '>Code</button> </a>
                    </div>
                </div>
            </div>
            {/* Grid item */}
            <div style={{backgroundImage: `url(${CryptohunterImg})`}} className='shadow-lg shadow-[#040c16] group container rounded-md flex justify-center items-center mx-auto content-div '>

                {/* Hover Effect */}
                <div className='opacity-0 group-hover:opacity-100 content-div'>
                    <div className='text-center justify-center mt-[60px]'> 
                    <span className='text-2xl font-bold text-white '>
                      React Js Application
                    </span>
                    </div>
                    <div className='pt-[15px] text-center    '>
                        <a href="https://cryptohunter.pages.dev/" target="_blank"  rel="noreferrer"> 
                        <button className='text-center rounded-lg px-4 py-3 m-2 bg-white text-gray-700 font-bold text-lg '>Demo</button> </a>
                        <a href="/"></a>
                        <button className='text-center rounded-lg px-4 py-3 m-2 bg-white text-gray-700 font-bold text-lg '>Code</button>
                    </div>
                </div>
            </div>
        </div>
       </div>
    </div>;
}


export default Work;